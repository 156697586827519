@font-face {
  font-family: Karasha;
  src: url(./Kashima\ Demo.otf);
}
.App {
  text-align: center;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  text-align: center;
}
.back {
  position: absolute;
  width: 100px;
  height: auto;
  left: 20px;
  top: 20px;
}
.flex-container {
  width: 1366px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.imgs img {
  width: 300px;
  height: auto;
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: black !important;
  box-shadow: none !important;
}
.connect {
  width: 300px;
  height: auto;
}
.gif {
  width: 500px;

  height: auto;
  margin-bottom: 20px;
}
.MuiButton-label {
  /* width: 6.7vw !important; */
  font-size: 30px !important;
  font-family: Karasha !important;
  text-align: center !important;
  padding-left: 0.4vw !important;
  padding-right: 0.4vw !important;
}
.sc-dlfnuX {
  box-shadow: none !important;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
  background-color: transparent !important;
}
@media screen and (max-width: 1200px) {
  .back {
    position: absolute;
    width: 50px;
    height: auto;
    left: 20px;
    top: 20px;
  }
  .flex-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .connect {
    width: 200px;
    height: auto;
  }
  .gif {
    width: 300px;

    height: auto;
    margin-bottom: 20px;
  }
  .imgs img {
    width: 200px;
    height: auto;
  }
  .MuiButton-containedPrimary {
    margin: 20px 0 20px 0 !important;
  }
  .MuiButton-label {
    /* width: 6.7vw !important; */
    font-size: 15px !important;
  }
}
